html {
  background-color: white;
  min-height: 400px;
}

.App {
  padding: 1em 1.5em;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Open sans", sans-serif;
  /* font-family: "Inter", sans-serif; */
  /* font-family: "Lato", sans-serif; */
}
