.news_item {
  padding: 0.5em 0;
  border-top: solid 1px #dee0e3;

  a.headline {
    font-size: 135%;
    font-weight: 800;
    color: #0d2880;
    text-decoration: none;
    margin-bottom: 0.4em;
    display: block;
  }
  a.headline:hover {
    text-decoration: underline;
  }
  .data_source {
    font-size: 90%;
    margin-bottom: 0.7em;

    .date {
      padding-right: 0.4em;
    }
    .source {
      padding-left: 0.4em;
    }
  }
  .summary {
    margin-bottom: 0.7em;
  }
}
