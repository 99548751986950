.news {
  .backButton {
    margin-bottom: 1em;
  }
  .headline {
    font-size: 2em;
  }
  .company {
    font-size: 1.5em;
  }
  .content {
    /* make tables scrollable on smaller screen */
    .table-container {
      width: 100% !important;
      overflow-x: scroll;
    }
    a {
      color: #0d2880;
    }
  }
}
